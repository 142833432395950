import { toast } from "react-toastify";

let isCopying = false; // Флаг, предотвращающий спам

export const copyToClipboard = async (text, notificationText) => {
  if (isCopying) return; // Если копирование уже выполняется, игнорируем вызов

  isCopying = true; // Устанавливаем флаг
  setTimeout(() => {
    isCopying = false; // Сбрасываем флаг через 2 секунды
  }, 2000);

  try {
    await navigator.clipboard.writeText(text);
    toast.info( notificationText ? notificationText : "Текст успешно скопирован!", { autoClose: 2000 });
    console.log("Текст успешно скопирован: ", text);
  } catch (error) {
    toast.error("Не удалось скопировать текст: " + error, { autoClose: 2000 });
    console.error("Ошибка копирования текста: ", error);
  }
};
